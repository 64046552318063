.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 5px;
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.menuItem {
  padding: 0;
  border: none;
  background: none;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.icon {
  width: 20px;
  height: 20px;
}
