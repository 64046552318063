@use 'src/styles/typography';
@use 'src/styles/templates';

:root[data-theme='light'] {
  --sidebar-menu-item-wrapper-hover: rgba(241, 244, 250, 1);
}

:root[data-theme='dark'] {
  --sidebar-menu-item-wrapper-hover: rgba(0, 0, 0, 0.1);
}

$notifColor: rgba(255, 105, 180, 1);
$notifBgColor: rgba(255, 105, 180, 0.1);

.wrapper {
  @extend %ghost-button;

  width: 100%;
  height: 48px;
  margin-bottom: 8px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  transition: var(--transition);

  &:hover {
    background-color: var(----sidebar-menu-item-wrapper-hover);
  }

  &__active {
    background-color: var(--primary-3);
    &:hover {
      background-color: var(--primary-3);
    }
  }

  &__opened {
    border-radius: 0 8px 8px 0;
  }

  &:focus-visible {
    outline: 1px solid var(--contrast);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.textWrapper {
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  display: flex;
  align-items: center;

  &__opened {
    visibility: visible;
    opacity: 1;
  }
}

.text {
  @extend %h3;
  @extend %text-ellipsis;

  color: var(--contrast);
  margin-bottom: 0;
  opacity: 0.75;

  &__active {
    color: var(--brand-1);
    opacity: 1;
  }
}

.iconWrapper {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transition: var(--transition);
  position: relative;
}

.icon {
  fill: var(--contrast);
  stroke: var(--contrast);
  opacity: 0.75;
  transition: var(--transition);

  &__active {
    fill: var(--brand-1);
    stroke: var(--brand-1);
    opacity: 1;
  }

  &__dark {
    opacity: 1;
  }
}

.notificationsIconBall {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: $notifColor;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
}

.notificationsCount {
  color: $notifColor;
  font-weight: 400;
  min-width: 16px;
  height: 16px;
  padding: 0 4px;
  margin-bottom: 0;
  margin-left: 16px;
  border-radius: 7px;
  background-color: $notifBgColor;
}
