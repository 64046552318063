@use 'src/styles/typography';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 4px;
  border-bottom: 1px solid var(--secondary-2);
}

.lastItem {
  border: none;
}

.valueText {
  @extend .body-m;
}

.deleteButton {
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent;
}
