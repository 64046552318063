@import '../../styles/typography';

.wrapper {
  width: 100%;
  min-height: 64px;
  padding: 0 16px;
  display: flex;
  transition: var(--transition);
  background-color: transparent;
}

.title {
  @extend %h2;

  color: var(--contrast);
  margin-left: 12px;
  margin-bottom: 0;
  transition: var(--transition);

  &Wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.burger {
  &Wrapper {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &Stick {
    width: 14px;
    height: 1px;
    margin-bottom: 5px;
    background-color: var(--contrast);
    transition: var(--transition);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
