@use 'src/styles/typography';

$dropAreaBgColor: rgba(210, 223, 250, 0.5);

.wrapper {
  width: calc(40% + 32px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.header {
  padding-left: 32px;
}

.descText {
  @extend %body-m;

  color: var(--contrast);
  width: 370px;
  padding-left: 32px;
  opacity: 0.5;
  transition: var(--transition);
}

.invalidItemsWrapper {
  margin-top: 16px;
  padding-left: 32px;
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
}

.dropArea {
  width: 100%;
  height: 100%;
  background-color: $dropAreaBgColor;
  position: absolute;
  top: 0;
  left: 0;
}
