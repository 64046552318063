[data-theme='light'] {
  --icon-button-background-color: var(--white-color);
}

[data-theme='dark'] {
  --icon-button-background-color: var(--primary-1);
}

.button {
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  border-radius: 4px;
  background-color: var(--icon-button-background-color);
  border: none;
  outline: none;
  cursor: pointer;
}

.iconWrapper {
  width: 24px;
  height: 24px;
}
