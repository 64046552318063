@import 'src/styles/typography';
@import 'src/styles/templates';

:root[data-theme='light'] {
  --new-well-section-bg-color: rgba(255, 255, 255, 0.5);
}

:root[data-theme='dark'] {
  --new-well-section-bg-color: rgba(54, 65, 83, 0.5);
}

.wrapper {
  padding-bottom: 24px;
  margin-bottom: 12px;
  background-color: var(--new-well-section-bg-color);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: flex-start;

  &__active {
    opacity: 0;
  }

  &__overlay {
    position: relative;
    z-index: 1;
    cursor: grabbing;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: grabbing;
    }
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
}

.deleteButton {
  padding: 4px;
  margin-right: 4px;
}

.dotsIcon {
  color: var(--contrast);
  cursor: grab;
  position: relative;
  margin-right: 4px;

  &:active {
    cursor: grabbing;
  }
}

.field {
  width: 50%;
}

.fieldsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 24px 8px;
}
