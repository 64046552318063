@use 'src/styles/templates';

.button {
  @extend %ghost-button;

  width: 100%;
  margin: 16px auto 0;
  padding: 4px 8px;
}

.buttonText {
  color: var(--brand-1);
}
