@use 'src/styles/templates';
@use 'src/styles/typography';

.card {
  @extend %ghost-button;

  width: 100%;
  height: 40px;
  padding: 0 12px 0 16px;
  border-radius: 8px;
  background-color: var(--primary-2);
  display: flex;
  align-items: center;
}

.directoryTitle {
  @extend .body-m-bold;
  @extend %text-ellipsis;

  width: 100%;
  padding-left: 16px;
  text-align: start;
  color: var(--contrast);
  transition: var(--transition);
}

.icon {
  stroke: var(--contrast);
  transition: var(--transition);
}

.bookIcon {
  width: 24px;
  height: 24px;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  rotate: -90deg;
}
