.container {
  max-width: 100%;

  :global {
    .ant-typography {
      color: var(--contrast);
      margin: 0;
    }
  }
}
