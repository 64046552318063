:root {
  --red-color: rgba(235, 87, 87, 1);
  --white-color: rgba(255, 255, 255, 1);
  --green-color: rgba(33, 150, 83, 1);
  --purple-color: rgba(101, 98, 245, 1);
}

:root[data-theme='light'] {
  --brand-1: rgba(58, 54, 219, 1);
  --brand-2: rgba(101, 98, 245, 1);
  --brand-3: rgba(58, 54, 219, 0.4);
  --primary-1: rgba(241, 244, 250, 1);
  --primary-2: rgba(210, 223, 250, 1);
  --primary-3: rgba(190, 209, 250, 1);
  --secondary-1: rgba(229, 229, 229, 1);
  --contrast: rgba(6, 21, 43, 1);
  --contrast-transparent: rgba(6, 21, 43, 0.6);
  --background-1: var(--white-color);
}

:root[data-theme='dark'] {
  --brand-1: rgba(96, 92, 255, 1);
  --brand-2: rgba(69, 65, 201, 1);
  --brand-3: rgba(96, 92, 255, 0.4);
  --primary-1: rgba(54, 65, 83, 1);
  --primary-2: rgba(40, 49, 63, 1);
  --primary-3: rgba(26, 32, 44, 1);
  --secondary-1: rgba(255, 255, 255, 0.5);
  --secondary-2: rgba(255, 255, 255, 0.1);
  --contrast: rgba(255, 255, 255, 1);
  --contrast-transparent: rgba(255, 255, 255, 0.5);
  --background-1: var(--primary-2);
}
