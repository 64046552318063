.container {
  width: inherit;

  input {
    background: transparent;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--contrast);
  }
}
