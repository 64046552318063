@use 'src/styles/typography';

:root[data-theme='light'] {
  --matching-page-matching-item-border-color: rgba(0, 0, 0, 0.1);
}

:root[data-theme='dark'] {
  --matching-page-matching-item-border-color: rgba(255, 255, 255, 0.05);
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 40px;
  border-bottom: 1px solid var(--matching-page-matching-item-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: var(--transition);

  &__empty {
    background-color: rgba(235, 87, 87, 0.15);
  }
}

.text {
  @extend %body-l;

  color: var(--contrast);
  transition: var(--transition);

  &__emptyRequired {
    color: var(--red-color);
  }

  &__emptyNonRequired {
    color: var(--contrast-transparent);
  }

  &__hovered {
    color: var(--brand-1);
  }
}

.newTextWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}
