:root[data-theme='light'] {
  --table-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --table-bg-color: var(--primary-1);
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &Resizable {
    cursor: col-resize;
    user-select: none;
    -webkit-user-select: none;

    form {
      p {
        cursor: col-resize;
      }
    }
  }
}
