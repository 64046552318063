@use 'src/styles/typography';

.panel {
  @extend .h3;
}

.groupWrapper {
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
