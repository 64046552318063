@use 'src/styles/typography';

:root[data-theme='light'] {
  --new-well-form-bg-color: white;
}

:root[data-theme='dark'] {
  --new-well-form-bg-color: var(--primary-1);
}

.wrapper {
  width: 100%;
  max-height: 100%;
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: var(--transition);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &__noBgColor {
    background-color: transparent;
    padding: 0;
  }
}

.groupWrapper {
  display: flex;
  flex-direction: column;
}

.columnsWrapper {
  display: flex;
  gap: 16px;
}

.groupLabel {
  @extend .body-m-bold;

  color: var(--contrast);
  padding-bottom: 24px;
}
