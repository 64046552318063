@import 'src/styles/typography';

.innerWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.primitivelabel {
  @extend .caption;

  color: var(--contrast);
  transition: var(--transition);
  opacity: 0.6;
}

.valueLabel {
  @extend .body-m;

  color: var(--contrast);
}
