@use 'src/styles/typography';
@use 'src/styles/templates';

.itemNameWrapper {
  @extend %body-m-bold;

  margin-right: 4px;
  display: flex;
  flex-grow: 1;
  color: var(--contrast);
  transition: var(--transition);
}

.itemName {
  @extend %text-ellipsis;
}

.wrapper {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemWrapper {
  width: 100%;
  min-height: 32px;
  padding: 4px 0px;
}

.switcherWrapper {
  width: 33.33%;
}

.labelWrapper {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.primitiveLabelWrapper {
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-top: -20px;
  margin-bottom: 0px;
}

.comment {
  @extend .caption;

  color: var(--contrast);
  opacity: 0.5;
}
