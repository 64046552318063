@use 'src/styles/templates';
@use 'src/styles/typography';

:root[data-theme='light'] {
  --datepicker-background-color: #fff;
  --related-directories-icon-color: #06152b;
}

:root[data-theme='dark'] {
  --datepicker-background-color: #364153;
  --related-directories-icon-color: #fff;
}

.subheader {
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.input {
  background-color: var(--datepicker-background-color);
}

.relatedDirectoriesButton {
  @extend %ghost-button;

  height: 24px;
}

.relatedDirectoriesIcon {
  fill: var(--related-directories-icon-color);
  stroke: var(--related-directories-icon-color);
}

.tableContainer {
  margin: 16px 0 0 16px;
  border-radius: 8px;
  background: var(--table-bg-color);
  height: 100%;
}

.leftSubheaderPart {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.directoryName {
  @extend .h2;

  color: var(--contrast);
  transition: var(--transition);
}

.backArrow {
  margin-right: 8px;
}

.copyModalDescription {
  margin-bottom: 32px;
  margin-top: 24px;
  color: var(--contrast);
  opacity: 0.75;
}

.modalButtonsWrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  width: 100%;
  margin-top: 54px;
}

.modalButton {
  width: 100%;
}
