@import 'src/styles/typography';

.wrapper {
  min-width: 20px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.label {
  @extend .body-m;

  margin-left: 16px;
  color: var(--contrast);
}
