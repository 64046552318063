@import 'palette';
@import 'fonts';
@import 'typography';
@import 'variables';
@import 'templates';

:root[data-theme='light'] {
  --ant-select-dropdown-bg-color: var(--white-color);
  --ant-select-item-option-selected-bg-color: var(--primary-1);
  --input-bg-color: white;
  --input-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --ant-select-dropdown-bg-color: var(--primary-2);
  --ant-select-item-option-selected-bg-color: var(--primary-1);
  --input-bg-color: var(--primary-2);
  --input-border-color: transparent;
}

.ant-message {
  &-notice {
    text-align: right;
  }

  &-notice-content,
  .anticon {
    color: var(--white-color);
  }
}

.errorMessage {
  .ant-message-notice-content {
    background: var(--red-color);
  }
}

.ant-switch {
  background: var(--primary-3);
}

.ant-switch-checked {
  background: var(--brand-1);

  &:focus {
    box-shadow: none;
  }
}

.ant-switch-inner {
  display: flex;
}

.ant-tooltip-inner {
  color: var(--contrast);
  border-radius: 8px;
  background-color: var(--primary-2);
}

.ant-tooltip-arrow-content::before {
  background: var(--primary-2);
}

.ant-progress-inner {
  transition: var(--transition);
}

%input {
  color: var(--contrast);
  background-color: var(--input-bg-color) !important;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  transition: var(--transition);
}

.ant-input {
  @extend %input;
}

.ant-input-affix-wrapper {
  @extend %input;
}

.ant-input-suffix {
  flex-direction: row-reverse;
}

.ant-input-clear-icon {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-input-clear-icon-has-suffix {
  margin-right: 0 !important;
  margin-left: 4px;
}

.ant-input-number {
  @extend %input;
}

.ant-select-selector {
  border-radius: 4px !important;
}

.ant-select-selection-item {
  color: var(--contrast);
  transition: var(--transition);
}

.ant-select-dropdown {
  background: var(--ant-select-dropdown-bg-color);
  box-shadow: none;

  .ant-select-item-option-selected {
    background: var(--ant-select-item-option-selected-bg-color);
  }

  .ant-select-item {
    &:hover {
      background: var(--ant-select-item-option-selected-bg-color);
    }
  }

  .ant-select-item {
    color: var(--contrast);
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: var(--contrast);
  opacity: 0.3;
}

.ant-select-clear {
  top: 45%;
  right: 17px;
  background-color: inherit;
}

.ant-select-selection-placeholder,
.ant-input::placeholder {
  color: var(--contrast);
  opacity: 0.5;
}
