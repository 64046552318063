:root[data-theme='light'] {
  --new-well-regular-select-border: var(--primary-3);
  --new-well-regular-select-hover-border-color: rgba(101, 98, 245, 0.7);
  --new-well-regular-select-bg: var(--white-color);
  --select-option-hover-color: rgba(58, 54, 219, 0.2);
  --close-icon-color: #06152b;
}

:root[data-theme='dark'] {
  --new-well-regular-select-border: var(--primary-2);
  --new-well-regular-select-hover-border-color: rgba(69, 65, 201, 0.7);
  --new-well-regular-select-bg: var(--primary-2);
  --select-option-hover-color: rgba(96, 92, 255, 0.2);
  --close-icon-color: hsla(0, 0%, 100%, 0.45);
}

.wrapper {
  :global {
    .ant-select * {
      transition: var(--transition);
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: var(--red-color) !important;
    }
    .ant-select-arrow {
      right: 4px;
    }
    .ant-select-clear {
      right: 10px;
    }
    .ant-select-focused {
      box-shadow: 0 4px 4px rgba(58, 54, 219, 0.25);
      z-index: 3;
    }
    .ant-select-disabled {
      border: 1px solid var(--new-well-regular-select-border);
      background-color: var(--new-well-regular-select-bg);

      &:hover {
        border: 1px solid var(--new-well-regular-select-border);
        background-color: var(--new-well-regular-select-bg);
      }
    }
    .ant-select-multiple .ant-select-selection-overflow-item .ant-select-selection-item-remove,
    .ant-select-clear {
      color: var(--close-icon-color);
    }
    .ant-select-disabled .ant-select-arrow {
      opacity: 0.1;
      transition: var(--transition);
    }
    .ant-select-disabled .ant-select-selector .ant-select-selection-placeholder {
      opacity: 0.1;
      transition: var(--transition);
    }
  }
}

.select {
  color: var(--contrast);
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--new-well-regular-select-border);
  background-color: var(--new-well-regular-select-bg);
  transition: var(--transition);

  &__error {
    border-color: var(--red-color);
  }

  &:hover {
    border-color: var(--new-well-regular-select-hover-border-color);
  }

  &:focus-within {
    border: 1px solid var(--brand-2);
  }
}

.loaderWrapper {
  margin-top: 4px;
}

.icon {
  transition: var(--transition);
}
