@use 'src/styles/templates';

:root[data-theme='light'] {
  --card-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --card-bg-color: var(--primary-1);
}

.wrapper {
  @extend %ghost-button;

  width: 290px;
  height: 72px;
  padding: 16px;
  padding-bottom: 0;
  border-radius: 8px;
  background-color: var(--card-bg-color);
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.icon {
  stroke: var(--contrast);
  transition: var(--transition);
  min-width: 36px;
}

.text {
  text-align: start;
  color: var(--contrast);
  font-weight: 700;
}
