.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.newDrillingRigInputsWrapper {
  flex-grow: 1;
  overflow: hidden;
}

.buttonsWrapper {
  padding: 28px 0 24px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.button {
  width: 178px;
}
