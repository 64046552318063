.logoWrapper {
  height: 56px;
  max-height: 56px;
  margin: 24px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.logoSvg {
  width: fit-content;
  min-width: fit-content;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}
