@use 'src/styles/typography';

:root[data-theme='light'] {
  --table-bg-color: var(--white-color);
  --table-cell-border-color: var(--secondary-1);
}

:root[data-theme='dark'] {
  --table-bg-color: var(--primary-1);
  --table-cell-border-color: var(--secondary-2);
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 16px 0 0 16px;
}

.tableBodyContainer {
  display: flex;

  .fixedColumn {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: inherit;

    &Last {
      border-right: 2px solid var(--table-cell-border-color);
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.virtualTableGrid {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
}

.fixedTableCell {
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--contrast);
  background: transparent;
  border-bottom: 1px solid var(--table-cell-border-color);
  display: flex;
  align-items: center;
}

.tableContainer {
  :global {
    .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
      box-shadow: none;
    }

    .ant-table-thead {
      tr {
        th {
          border-bottom: 1px solid var(--table-cell-border-color);
          padding: 8px;
          background: transparent;
          font-weight: 700;
          font-size: 15px;
          color: var(--contrast);
          vertical-align: top;

          &[colspan]:not([colspan='1']) {
            text-align: left;
          }

          &::before {
            top: 16px !important;
            background-color: var(--table-cell-border-color) !important;
          }

          &:hover {
            background: transparent;

            &::before {
              background-color: var(--table-cell-border-color) !important;
            }
          }
        }

        &:nth-child(2) {
          th {
            padding: 0 8px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);

            &::before {
              display: none;
            }
          }
        }
      }
    }

    .virtual-table-cell {
      display: flex;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid var(--table-cell-border-color);
      background: transparent;
      font-weight: 400;
      font-size: 14px;
      color: var(--contrast);
    }

    .ParentColumn {
      overflow: visible;

      &::after {
        position: absolute;
        top: 6px;
        right: 0;
        width: 1px;
        height: 1.6em;
        background-color: var(--table-cell-border-color);
        transform: translateY(-10%);
        content: '';
      }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover,
    .ant-table,
    .ant-table-tbody > tr > td,
    td.ant-table-column-sort {
      background: transparent;

      &:hover {
        background: transparent;
      }
    }

    .ant-table-tbody > tr > td {
      border-bottom: 1px solid var(--table-cell-border-color);
      padding: 8px;
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: var(--table-bg-color) !important;
    }

    .ant-table-cell-fix-left-last {
      border-right: 2px solid var(--table-cell-border-color);

      &::before {
        display: none;
      }

      &::after {
        box-shadow: none;
      }
    }

    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: none;
    }

    .ant-table-column-sorter {
      display: flex;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: var(--brand-1);
    }
  }
}
