@use 'src/styles/typography';

.wrapper {
  padding: 8px 0;
  margin: 0;
  border-radius: 4px;
  background-color: var(--primary-2);
  transition: var(--transition);
}

.itemWrapper {
  padding: 0 16px;
  min-height: 32px;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(96, 92, 255, 0.4);
    transition: var(--transition);
  }
}

.itemText {
  @extend .body-m;

  color: var(--contrast);
  transition: var(--transition);
}
