@use 'src/styles/typography';

:root[data-theme='light'] {
  --new-well-input-disabled-color: white;
  --new-well-input-border-color: rgba(101, 98, 245, 0.7);
}

:root[data-theme='dark'] {
  --new-well-input-disabled-color: black;
  --new-well-input-border-color: rgba(69, 65, 201, 0.7);
}

.inputWrapper {
  :global(.ant-input-affix-wrapper *) {
    transition: var(--transition);
  }

  :global(.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless)) {
    border-color: var(--red-color) !important;
  }

  :global(.ant-input-affix-wrapper-focused) {
    border-color: var(--brand-2);
    box-shadow: none;
    &:hover {
      border-color: var(--brand-2);
    }
  }

  :global(.ant-input-affix-wrapper-disabled .ant-input-suffix) {
    opacity: 0.7;
  }
}

.input {
  width: 100%;
  color: var(--contrast);

  &:not(:global(.ant-input-affix-wrapper-disabled)) {
    &:hover {
      border-color: var(--new-well-input-border-color) !important;
    }
  }

  :global(.ant-input *) {
    transition: var(--transition);
  }

  :global(.ant-input-disabled) {
    opacity: 0.5;
    color: var(--contrast);
    transition: var(--transition);
  }
}

.text {
  @extend %body-m;

  color: var(--contrast);
  opacity: 0.7;
  transition: var(--transition);
}

.iconWrapper {
  min-width: 12px;
  min-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 8px;
  height: 8px;
  display: inline-block;
  stroke: var(--contrast);
  transition: var(--transition);
}
