@use 'src/styles/typography';

:root[data-theme='light'] {
  --upload-plan-modal-drag-area-bg: var(--primary-1);
  --upload-plan-modal-create-area-bg: var(--primary-1);
}

:root[data-theme='dark'] {
  --upload-plan-modal-drag-area-bg: var(--primary-2);
  --upload-plan-modal-create-area-bg: var(--primary-2);
}

.text {
  @extend %body-m;

  color: var(--contrast);
}

.infoWrapper {
  display: flex;
  align-items: center;
}

.plus {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 10px;
    height: 1px;
    background-color: white;
    position: absolute;
  }

  &::after {
    transform: rotate(90deg);
  }
}
