@use 'src/styles/typography';
@use 'src/styles/templates';

:root[data-theme='light'] {
  --sidebar-component-close-button: var(--contrast);
}

:root[data-theme='dark'] {
  --sidebar-component-close-button: rgba(204, 210, 227, 1);
}

$wrapperShadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 12px 27px 0px rgba(0, 0, 0, 0.19),
  0px 50px 50px 0px rgba(0, 0, 0, 0.17), 0px 112px 67px 0px rgba(0, 0, 0, 0.1), 0px 200px 80px 0px rgba(0, 0, 0, 0.03),
  0px 312px 87px 0px rgba(0, 0, 0, 0);

.wrapper {
  width: 0px;
  height: 100%;
  background-color: var(--primary-1);
  opacity: 0;
  box-shadow: $wrapperShadow;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 25;
  overflow: hidden;
  transition: var(--transition);

  &__m {
    width: 400px;
  }

  &__l {
    width: 600px;
  }

  &__opened {
    opacity: 1;
  }
}

.contentWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__m {
    width: 400px;
  }

  &__l {
    width: 600px;
  }
}

.closeButton {
  @extend %ghost-button;

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 16px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 16px;
    background-color: var(--sidebar-component-close-button);
    transition: var(--transition);
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
}

.title {
  @extend %h1;

  color: var(--contrast);
  padding: 16px 40px 16px 24px;
  transition: var(--transition);
}

.descriptionText {
  @extend .body-m;

  color: var(--contrast);
  opacity: 0.55;
  transition: var(--transition);
  padding: 0 16px 0 24px;
}
