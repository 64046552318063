@use 'src/styles/typography';

:root[data-theme='light'] {
  --collapse-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --collapse-border-color: rgba(255, 255, 255, 0.1);
}

.ant-collapse {
  border: none;
  background: transparent;
  transition: var(--transition);

  .ant-collapse {
    margin-left: 12px;
  }

  > .ant-collapse-item {
    border-bottom: none;

    &:last-child {
      .ant-collapse-content {
        background-color: transparent;
      }
    }

    .ant-collapse-header {
      height: 32px;
      padding: 4px;

      .ant-collapse-expand-icon {
        margin-right: 8px;
      }

      .ant-collapse-header-text {
        color: var(--contrast);
        transition: var(--transition);
      }

      .ant-collapse-arrow {
        margin-right: 1.32px;
      }
    }
  }

  .ant-collapse-content {
    border: none;
    background: var(--primary-1);
    transition: var(--transition);

    > .ant-collapse-content-box {
      color: var(--contrast);
      padding: 0;
      transition: var(--transition);
    }
  }

  &.bordered {
    .ant-collapse-header {
      height: 36px;
      margin-top: 24px;
      padding: 0 4px 8px 0;
      border-bottom: 1px solid var(--collapse-border-color);
    }

    .ant-collapse-content-box {
      padding: 8px 0;
    }

    .ant-collapse-item:not(:last-of-type) .ant-collapse-content-box {
      border-bottom: 1px solid var(--collapse-border-color);
    }
  }
}
