%text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%ghost-button {
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: inherit;
  }
}

%close-cross {
  @extend %ghost-button;

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 12px;
    background-color: var(--sidebar-component-close-button);
    transition: var(--transition);
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
}

%grabbing {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
