@use 'src/styles/typography';

.wrapper {
  min-height: 32px;
  position: relative;
  z-index: 1;
  padding: 0 4px;
  border-radius: 4px;
  background-color: var(--primary-2);

  &Empty {
    height: 32px;
  }

  &Hidden {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: calc(100% - 16px);
      left: 8px;
      height: 12px;
      background: linear-gradient(to top, var(--primary-2), transparent);
      z-index: 2;
    }
  }
}

.collapseItemsWrapper {
  min-height: 32px;
  position: relative;
  background-color: var(--primary-2);
  z-index: 2;
  max-height: 320px;
  overflow: auto;

  &Hidden {
    min-height: 48px;
    max-height: 48px;
    overflow: hidden;
  }
}

.toggleOpenButton {
  @extend .caption;

  width: 100px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  top: calc(100% - 4px);
  right: calc(50% - 0px);
  border: none;
  border-top: 1px solid var(--secondary-2);
  border-radius: 0 0 6px 6px;
  background-color: var(--primary-2);
  cursor: pointer;
  transition: top var(--transition);

  &:hover {
    top: 100%;
  }
}

.emptyList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyListText {
  @extend .caption;

  color: var(--secondary-1);
}
